.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
}
.card {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 20px;
}
.section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 20px;
}
.messageText {
  font-size: 20px;
  font-weight: 600;
  color: #000;
  margin: 20px;
}

.cardSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 20px;
}
.circle {
  border-radius: 50%; /* Makes the element a circle */
  border: 2px solid #910000; /* Adds a border */
  display: inline-block; /* Makes the element an inline block so it can be positioned alongside text */
  width: 25px; /* Sets the width of the element */
  height: 25px; /* Sets the height of the element */
  text-align: center; /* Centers the text inside the element */
  line-height: 25px; /* Centers the text vertically within the element */
  margin-left: 5px;
  color: #fff;
  background-color: #000000;
}
.orderNumber {
  font-size: 20px;
  font-weight: 600;
  margin: 20px;
  background-color: #033a4797;
  padding: 10px;
  border-radius: 5px;
  /* stretch the background color width */
  width: 100%;
  /* center the text */
  text-align: center;
  /* make the text white */
  color: #fff;
}
