.TableContainer {
  margin-top: 40px !important;
}
.MuiTableRow-root > * {
  padding: 10px;
}
.MuiTableRow-root.MuiTableRow-head > * {
  font-weight: bold !important;
}

.Table td,
th {
  border: none !important;
}

.Table:first-child {
  border-radius: 0.7rem !important;
}

.status {
  padding: 8px;
  border-radius: 9px;
}

.Details {
  color: #0066c6 !important;
}

.Details:hover {
  color: #ff6a00 !important;
  cursor: pointer;
}
.tableX tr:nth-child(even) {
  background-color: #f2f2f2;
}
.tableX tr:nth-child(odd) {
  background-color: rgb(202, 202, 202);
}

.MuiTableHead-root.TableHead {
  background-color: #9bdcf0;
}

@media screen and (max-width: 1200px) {
  .Table {
    width: 170%;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .Table {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 98%;
  }
}

/* Pixel 5 */
@media (max-width: 393px) {
  .TableContainer {
    display: block;
    overflow-y: auto;
    width: 300px !important;
  }
}

/* Galaxy Fold */
@media (max-width: 280px) {
  .TableContainer {
    display: block;
    overflow-y: auto;
    width: 350px;
  }
}
/* SamSung Galaxy A51/71 */
@media (max-width: 412px) {
  .TableContainer {
    display: block;
    overflow-y: auto;
    width: 450px;
  }
}
