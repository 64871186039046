.payment-options-container {
    padding: 20px;
    text-align: center;
  }
  
  .payment-options-title {
    margin-bottom: 15px;
    font-size: 2em;
  }
  
  .payment-options-row {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 17px;
    align-items: center;
  }
  
  .payment-option-circle {
    width: 120px; /* Control the size of the circles here */
    height: 120px;
    border-radius: 50%; /* Makes the div circular */
    overflow: hidden; /* Ensures the images are clipped to a circle */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff; /* Set a background color */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effect */
  }
  
  .payment-option-circle:hover {
    transform: scale(1.05); /* Slightly scale up the card */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* More pronounced shadow */
    cursor: pointer; /* Change the cursor to indicate it's clickable */
  }
  
  .payment-option-image {
    width: 80%; 
    height: auto; 
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .payment-options-row {
      flex-wrap: wrap;
    }
    .payment-option-circle {
      width: 90px;
      height: 90px;
    }
  }
  