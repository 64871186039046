.shipping-matrix-container {
    padding: 20px;
    max-width: 800px;
    margin: auto;
  }
  
  .shipping-matrix-table {
    width: 100%;
    /* border-collapse: separate; */
    /* border-spacing: 0;  */
    margin-top: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  }
  
  .shipping-matrix-table th,
  .shipping-matrix-table td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
  }
  
  .shipping-matrix-table th {
    background-color: #007bff; 
    color: #f2f2f2;
  }
  .shipping-matrix-table th:first-child {
    border-top-left-radius: 8px; 
  }
  .shipping-matrix-table th:last-child {
    border-top-right-radius: 8px;
  }
  
  .shipping-matrix-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .shipping-matrix-table tr:hover {
    background-color: #eaeaea;
  }
  @media (max-width: 768px) {
    .shipping-matrix-container {
      padding: 10px;
      padding-top: 40px;
    }
    .matrix-header {
      text-align: center;
    }
  
    .shipping-matrix-table {
      margin-top: 25px;
    }
  
    .shipping-matrix-table th,
    .shipping-matrix-table td {
      padding: 8px; 
    }
  }