.Sidebar {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 2rem;
  transition: all 300ms ease;
}
.logo {
  display: flex;
  height: 5rem;
  align-items: center;
  justify-content: center;
  height: 4%;
  font-weight: bold;
  font-size: 22px;
  gap: 1rem;
}
.logo > img {
  height: 5rem;
  width: 5rem;
}
/* menu */
.menu {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.menuItem {
  display: flex;
  align-items: center;
  gap: 1rem;
  height: 2.5rem;
  margin-left: 2rem;
  position: relative;
  transition: all 300ms ease;
  font-size: 14px;
}
.menuItem:hover {
  background-color: rgba(85, 255, 85, 0.499);
  border-radius: 4px;
  cursor: pointer;
}
.active {
  background-color: #5f5;
  border-radius: 4px;
  margin-left: 0;
}

/* For active MenuItem */
.active::before {
  content: "";
  width: 8px;
  height: 100%;
  background-color: rgb(1, 132, 1);

  /* border-radius: 4px; */
  margin-right: calc(1rem - 8px);
}
/* For pushing the last MenuItem (Logout) to the bottom */
.menu .menuItem:last-child {
  position: absolute;
  bottom: 2.3rem;
  width: 80%;
}
.menu .menuItem:last-child:hover {
  background-color: rgba(226, 0, 0, 0.499);
}
.bars {
  display: none;
}

@media screen and (max-width: 1200px) {
  .menuItem > span {
    display: none;
  }
  .logo {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .Sidebar {
    position: fixed;
    z-index: 9;
    background: rgba(16, 27, 16, 0.76);
    width: 30%;
    padding-right: 1rem;
    height: 100%;
  }
  .menuItem > span {
    display: block;
    color: #fff;
  }

  /* When menu is active, change menuItem black */
  .menuItem.active > span {
    display: block;
    color: #000;
  }
  .menuItem.active {
    color: #000;
  }

  .menuItem {
    color: #fff;
  }

  .logo {
    display: flex;
  }
  .menu .menuItem:last-child {
    position: relative;
    margin-top: 6rem;
  }
  /* Bars for Closing MenuItem on Small Screen */
  .bars {
    display: flex;
    position: fixed;
    top: 20.5%;
    z-index: 12;
    background: rgba(16, 27, 16, 0.76);
    padding: 8px;
    border-radius: 10px;
  }
}
