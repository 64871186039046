.Promotions {
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.PromotionsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px #094f651a;
  padding: 20px;
  margin: 20px;
}

.header {
  -webkit-text-size-adjust: 100%;
  color: #282828;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, Segoe UI,
    Helvetica Neue, Arial, sans-serif;
  font-size: 0.875rem;
  -webkit-font-smoothing: antialiased;
  direction: ltr;
  box-sizing: border-box;
  border-bottom: 1px solid #ededed;
  display: flex;
  align-items: center;
  min-height: 48px;
  height: 0;
  padding-top: 8px;
  padding-right: 16px;
  padding-bottom: 8px;
  padding-left: 16px;
}
.voucherText {
  -webkit-text-size-adjust: 100%;
  color: #282828;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, Segoe UI,
    Helvetica Neue, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  direction: ltr;
  box-sizing: border-box;
  /* padding: 0; */
  margin: 0;
  overflow: hidden;
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 1.25rem;
  color: #094f65;
  background-color: #9ade12;
  padding: 0.5rem;
  border-radius: 5px;
}
.voucherRow {
  -webkit-text-size-adjust: 100%;
  color: #282828;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, Segoe UI,
    Helvetica Neue, Arial, sans-serif;
  font-size: 0.875rem;
  -webkit-font-smoothing: antialiased;
  direction: ltr;
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1184px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 8px;
  padding-left: 8px;
}
.col16 {
  -webkit-text-size-adjust: 100%;
  color: #282828;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, Segoe UI,
    Helvetica Neue, Arial, sans-serif;
  font-size: 0.875rem;
  -webkit-font-smoothing: antialiased;
  direction: ltr;
  box-sizing: border-box;
  padding-right: 8px;
  padding-left: 8px;
  flex-basis: 100%;
  max-width: 100%;
  min-width: 100%;
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: center;
}

.tab {
  -webkit-text-size-adjust: 100%;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, Segoe UI,
    Helvetica Neue, Arial, sans-serif;
  font-size: 0.875rem;
  -webkit-font-smoothing: antialiased;
  direction: ltr;
  text-align: center;
  box-sizing: border-box;
  display: flex;
  color: #75757a;
  font-weight: 500;
  text-transform: uppercase;
  position: relative;
}

.tabLinks {
  -webkit-text-size-adjust: 100%;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, Segoe UI,
    Helvetica Neue, Arial, sans-serif;
  font-size: 0.875rem;
  -webkit-font-smoothing: antialiased;
  direction: ltr;
  text-align: center;
  box-sizing: border-box;
  display: flex;
  color: #75757a;
  font-weight: 500;
  text-transform: uppercase;
  position: relative;
}

.col16 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px #094f651a;
  padding: 20px;
  margin: 20px;
}

.voucherImage {
  -webkit-text-size-adjust: 100%;
  color: #282828;
  /* font-family: Roboto, -apple-system, BlinkMacSystemFont, Segoe UI,
    Helvetica Neue, Arial, sans-serif; */
  font-size: 0.875rem;
  -webkit-font-smoothing: antialiased;
  direction: ltr;
  text-align: center;
  height: 100px;
  /* aspect-ratio: auto 100 / 100; */
  width: 100px;
  box-sizing: border-box;
  border-style: none;
  margin-top: 5px;
  margin-bottom: 8px;
}

.noVoucher {
  -webkit-text-size-adjust: 100%;
  color: #282828;
  font-size: 0.875rem;
  -webkit-font-smoothing: antialiased;
  direction: ltr;
  text-align: center;
  box-sizing: border-box;
  display: flex;
  color: #75757a;
  font-weight: 500;
  text-transform: capitalize;
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
}

.continueShopping {
  -webkit-text-size-adjust: 100%;
  color: #282828;
  font-size: 0.875rem;
  -webkit-font-smoothing: antialiased;
  direction: ltr;
  text-align: center;
  box-sizing: border-box;
  display: flex;
  color: #75757a;
  font-weight: 500;
  text-transform: capitalize;
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
}

.continueShoppingButton {
  -webkit-text-size-adjust: 100%;
  color: #282828;
  font-size: 0.875rem;
  -webkit-font-smoothing: antialiased;
  direction: ltr;
  text-align: center;
  box-sizing: border-box;
  display: flex;
  color: #75757a;
  font-weight: 500;
  text-transform: capitalize;
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: 1px solid #094f65;
  border-radius: 5px;
  padding: 10px 20px;
  color: #094f65;
  font-weight: 500;
  font-size: 1rem;
  cursor: pointer;
}

.continueShoppingButton:hover {
  background-color: #094f65;
  color: #fff;
}

@media (max-width: 393px) {
  .voucherRow {
    margin: 0 auto;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
  .voucherText {
    margin-left: 2rem;
    color: #094f65;
    background-color: #9ade12;
    padding: 0.5rem;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: 500;
  }
}
