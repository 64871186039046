* {
  margin: 0;
  padding: 0;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

/* Modal Button */
.modalButton {
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  padding: 12px 24px;
}

/* Modal */
.overlay {
  /* background-color: rgba(0, 0, 0, 0.7); */
  position: fixed;
  width: 100%;
  height: 100%;
}

.modalContainer {
  max-width: 750px;
  width: 100%;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  background-color: #ffffff;
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.75);
  border-radius: 8px;
}

img {
  width: 360px;
  height: 350px;
  object-fit: cover;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.modalRight {
  width: 100%;
}

.closeBtn {
  position: fixed;
  top: 8px;
  right: 8px;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-top: 3rem;
  padding: 1rem 2rem;
}

.btnContainer {
  display: flex;
  padding: 1rem 1rem;
}
.btnContainer button {
  width: 100%;
  margin: 0.5rem;
  padding: 16px 0;
  /* border: none; */
  border: 1px solid #411b57;
  /* color: #ffffff; */
}

.btnPrimary {
  background-color: #411b57;
  color: white;
}

.btnOutline {
  /* background-color: #a76a99; */
  background-color: white;
  color: #411b57;
}

.bold {
  font-weight: 600;
}

.paragraphPending {
  margin: 1rem;
  font-size: 1.8rem;
  font-style: italic;
  font-size: x-small;
}
.statusMessage {
  margin: 1rem;
  font-size: 1.2rem;
  font-style: italic;
  font-size: small;
  font-family: "Courier New", Courier, monospace;
  color: #029b17;
}
.order-summary-container {
  padding-top: 30px;
  margin: 0 auto;
  width: 70%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  margin-top: 20px;
  box-shadow: 0px 0px 18px 0px rgba(47, 142, 0, 0.75);
}
.order-summary-container-airtel {
  /* padding-top: 30px; */
  margin: 0 auto;
  width: 70%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  margin-top: 40px;
  box-shadow: 0px 0px 18px 0px rgba(47, 142, 0, 0.75);
}
@media screen and (max-width: 500px) {
  .modalContainer {
    flex-direction: column;
    top: 0;
    left: 0;
    transform: none;
    width: 100%;
    height: 100vh;
  }
  img {
    width: 100%;
    max-height: 70vh;
    object-fit: cover;
  }

  .heading {
    margin: 1rem;
  }
}
