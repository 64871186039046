.otp-card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 40vh; /* Use the full screen height */
  padding-top: 6rem;
  width: 100%;
}

.card-otp {
  padding: 2rem;
  background-color: #fff;
  width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

input[type="number"] {
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 100%;

  /* Hide the increment and decrement buttons */
  -webkit-appearance: none;
  -moz-appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="submit"] {
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

input[type="submit"]:disabled {
  background-color: #ffb3b3;
  cursor: not-allowed;
}

label {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
