.user-profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-right: 25%;
}
.email {
  -webkit-text-size-adjust: 100%;
  color: #282828;
  font-size: 0.875rem;
  -webkit-font-smoothing: antialiased;
}
.createdAt {
  -webkit-text-size-adjust: 100%;
  color: #282828;
  font-size: 0.775rem;
  -webkit-font-smoothing: antialiased;
}
.type {
  -webkit-text-size-adjust: 100%;
  color: #282828;
  font-size: 0.775rem;
  -webkit-font-smoothing: antialiased;
}

.passwordChange {
  background-color: #026873;
  border: none;
  color: white;
  padding: 10px 22px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  margin: 3px 2px;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 8px;
}

.passwordChange:hover {
  background-color: #45a049;
}
