.AppPanel {
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  /* box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05); */
  /* margin-bottom: 20px; */
  /* width: 100%; */
  height: 65vh;
  display: flex;
  justify-content: center;
  align-items: center;
  /* font-family: "Courier New", Courier, monospace; */
  font-family: "Dosis", sans-serif;
}
.AppGlass {
  display: grid;
  height: 97%;
  width: 97%;
  border-radius: 2rem;
  /* background-color: red; */
  background-color: var(--glass-color);
  overflow: hidden;
  grid-template-columns: 16rem auto;
  /* gap: 10px; */
}

@media screen and (max-width: 1200px) {
  .AppGlass {
    /* background-color: blue; */
    grid-template-columns: 10% 50% auto;
    overflow-y: scroll;
  }
}

@media screen and (max-width: 768px) {
  .AppGlass {
    grid-template-columns: 1fr;
  }
  .AppGlass::-webkit-scrollbar {
    display: none;
  }
}
