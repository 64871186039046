.sign_nav_container {
  /* height: 75px; */
  max-height: 60px;
  padding: 0;
}
.sign_container {
  display: flex;
  justify-content: center;
  margin: auto;
  width: 100%;
  height: 75vh;
  align-items: center;
  flex-direction: column;
}

.sign_form_container {
  display: flex;
  border-radius: 10px;
  width: 320px;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%),
    0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
}

.heading {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0 0 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo_signin {
  width: 115px;
  height: 115px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.input {
  width: 92%;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin: 0 auto 10px;
  padding: 0 10px;
  background-color: #edf5f3;
}

.sign_button {
  background-color: #017109;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  width: 100%;
  /* text-transform: uppercase; */
}
.google_sign_button {
  margin-top: 7px;
  width: 100%;
}

.error_msg {
  /* width: 370px; */
  padding: 5px 10px;
  margin: 1px 0;
  font-size: 10px;
  background-color: #f34646;
  color: white;
  border-radius: 5px;
  text-align: center;
  font-style: italic;
}
/* .nav_container {
  height: 75px;
  padding: 0;
}
.signup_container {
  width: 100%;
  min-height: 100vh;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center; */
/* background: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    url("https://images.unsplash.com/photo-1518791841217-8f162f1e1131?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80")
      center; */
/* background-size: cover;
  background-position: center;
} */

/* .signup_form_container {
  width: 900px;
  height: 500px;
  display: flex;
  border-radius: 10px;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%),
    0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
}

.left {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #3bb19b;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.left h1 {
  margin-top: 0;
  color: white;
  font-size: 34px;
  align-self: center;
}

.right {
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.form_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form_container h1 {
  font-size: 40px;
  margin-top: 0;
}

.input {
  outline: none;
  border: none;
  width: 370px;
  padding: 15px;
  border-radius: 10px;
  background-color: #edf5f3;
  margin: 5px 0;
  font-size: 14px;
}

.error_msg {
  width: 370px;
  padding: 15px;
  margin: 5px 0;
  font-size: 14px;
  background-color: #f34646;
  color: white;
  border-radius: 5px;
  text-align: center;
}

.white_btn {
  border: none;
  outline: none;
  padding: 12px 0;
  background-color: white;
  border-radius: 20px;
  width: 180px;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  margin-top: 10px;
}
.white_btn:hover {
  border: none;
  outline: none;
  padding: 12px 0;
  background-color: #007a78;
  color: #edf5f3;
  border-radius: 20px;
  width: 180px;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  margin-top: 10px;
}
.green_btn {
  border: none;
  outline: none;
  padding: 12px 0;
  background-color: white;
  border-radius: 20px;
  width: 180px;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  margin-top: 10px;
} */

/* .green_btn {
  background-color: #3bb19b;
  color: white;
  margin: 10px; */
/* @media (max-width: 768px) {
        width: 100%;
    } */
/* } */

/* @media (max-width: 600px) { */
/* .nav_container {
    min-width: 650px;
  }
  .signup_container {
    margin-top: 40px;
    background: none;
    margin: auto;
    margin-left: 140px;
  }
  .left {
    flex: 1;
    width: 250px;
  }
  .right {
    flex: 1;
  }
  .left h1 {
    font-size: 28px;
    padding-left: 5px;
  }
  .form_container {
    min-width: 380px;
  }
  .input {
    width: 290px;
  }
} */
