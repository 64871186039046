.step-indicator {
  width: 100%;
  max-width: 300px;
  display: flex;
  justify-content: space-around;
  padding: 10px;
  /* margin-bottom: 20px; */
}

.step {
  padding: 10px;
  border-radius: 10px;
  background-color: #c3c1c1;
  color: white;
  font-size: 10px;
}

.step.active {
  background-color: #0baf0b;
  color: white;
}

.step.completed {
  background-color: #0baf0b;
  color: white;
}
